import Vue from 'vue'
import VueRouter from 'vue-router'
import BookShelf from '../views/workflow/BookShelf.vue'
import WorkFlow from '../views/workflow/WorkFlow.vue'
import NotFound from '../views/error/NotFound.vue'
import ErrorPage from '../views/error/ErrorPage.vue'
import Login from '../views/user/Login.vue'
import store from '@/store'


Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    beforeEnter(to, from, next) {
      window.location.href = "https://proofcheck.io";
    }
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/bookshelf',
    name: 'BookShelf',
    component: BookShelf,
    meta: { requiresAuth: true },
  },
  {
    path: '/pdfworkflow',
    name: 'WorkFlow',
    component: WorkFlow,
    meta: { requiresAuth: true }
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage,
    props: true,
    meta: { requiresAuth: false }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.isAuthenticated) {
      next()
      return
    }
    next('/')
  }
  next()
})

export default router
