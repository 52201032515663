import Vue from 'vue'
import store from '../store/index.js'
import router from '../router'


const EXPRESS_URL = process.env.VUE_APP_EXPRESS_URL


async function createOrder(payload) {
    try {
        let orderRes = await axios.post(`${EXPRESS_URL}/orders`, payload);
    } catch (e) {
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${e.message} - createOrder (expressService.js)`,
                stackTrace: e.stack
            }
        })
    }
}

async function getCustomerInfo(uid) {
    try {
        console.log(`${EXPRESS_URL}/basicInfo/${uid}`)
        let basicInfo = await axios.get(`${EXPRESS_URL}/basicInfo/${uid}`)
        // get basic info
        let { Organization, FirstName, LastName, Price } = basicInfo.data;
        let config = await axios.get(`${EXPRESS_URL}/config/${Organization}`);

        // log sign-in 
        let activity = await axios.post(`${EXPRESS_URL}/activity`, {
            Organization: Organization,
        });
        let username = `${FirstName} ${LastName}`;

        console.log(username)
        return [username, Organization, Price, config.data]
    }
    catch (error) {
        console.log(error)
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${error.message} - getCustomerInfo (expressService.js)`,
                stackTrace: error.stack

            }
        })
    }
}

export { createOrder, getCustomerInfo }