<template>
  <v-app>
    <v-main>
      <v-row align="center" justify="center" class="loader" dense>
        <v-col cols="4">
        <v-row class="justify-center top-text">
          <h1> {{ message }}  </h1>
        </v-row>
          <LottieAnimation path="document_scan.json" />
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Jura&display=swap");

// $vert: 30vh;
// $horz: 15vw;

.loader {
  position: relative;
  top: 18vh !important;
  font-size: 30px !important;
  z-index: 99999999999999999999999 !important;
}

h1 {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 30px;
  font-weight: 200;
}

.v-application .primary {
  background-color: #38536d !important;
}

.theme--light.v-list {
  background: #5e9e2f14;
  color: rgba(0, 0, 0, 0.87);
}

.working {
  position: absolute !important;
  margin-left: 7% !important;
  margin-top: 2% !important;
}

.top-text{
  position: relative !important;
  top: 5vh !important;
}

.bottom-text {
  position: relative !important;
  bottom: 10vh !important;
}
</style>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  components: {
    LottieAnimation,
  },
  computed: {
    message() {
      return this.$store.getters.loadingMessage;
    }
  }
};
</script>
