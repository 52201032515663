<template>
  <v-app>
    <v-main>
      <v-app-bar color="rgb(94,140,148)" dark elevation="4">
        <router-link class="routerLink" :to="{ name: 'Home' }">
          <v-icon color="">mdi-home</v-icon>
        </router-link>
        <!-- <v-btn class="ml-3 mt-2" @click="$router.push('/home')"> Home </v-btn> -->
        <!-- <v-spacer /> -->
        <!-- <v-btn @click="$router.push('/home')"> Home </v-btn> -->
      </v-app-bar>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center" dense>
          <v-col cols="12" sm="8" md="4" lg="4">
            <v-sheet elevation="24">
              <v-card class="mx-auto" width="25vw" max-width="25vw" v-if="!dialog" elevation="0">
                <v-img class="mx-auto" src="@/assets/beta_logo.png" alt="" contain height="200" width="85%"></v-img>
                <v-card-text class="mt-n10">
                  <v-form @submit.prevent="submit">
                    <v-text-field label="Enter your email" name="email" v-model="email" prepend-inner-icon="mdi-email"
                      type="email" class="rounded-0" outlined></v-text-field>
                    <v-text-field v-if="showForgot" label="Enter your password" name="password" v-model="password"
                      prepend-inner-icon="mdi-lock" type="password" class="rounded-0" outlined></v-text-field>
                    <v-btn type="submit" class="rounded-0" color="#000000" x-large block dark rounded>{{
                      this.showForgot ? "Login" : "Reset" }}</v-btn>
                    <v-col class="justify-left mb-10">
                      <p class="nowrap conditions ml-n2">
                        By continuing you agree to Proofcheck GmbH's terms and
                        conditions as specified in your Service License
                        Agreement.
                      </p>

                      <v-btn class="resetbtn" v-if="showForgot" @click="showForgot = !showForgot"> Forgot password
                      </v-btn>

                      <v-spacer vertical />
                    </v-col>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-alert v-model="alert" dismissible color="cyan" border="left" elevation="2" colored-border>
      {{ errorMessage }}
    </v-alert>
  </v-app>
</template>

<script>
import {
  auth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "../../../firebase/config.js";

import { getCustomerInfo } from "../../services/expressService.js";

import router from "@/router";

const MONGO_URL = process.env.VUE_MONGODRIVER_URL;

export default {
  name: "Login",
  data() {
    return {
      dialog: false,
      auth: auth,
      email: "",
      password: "",
      alert: false,
      errorMessage: "",
      showForgot: true
    };
  },
  methods: {
    resetState() {
      this.showForgot = true
    },

    mounted() {
      this.resetState()
    },

    deactivate() {
      this.resetState()
    },

    destroyed() {
      this.resetState()
    },

    async sleep() {
      console.log('Before sleep');
      await new Promise(resolve => setTimeout(resolve, 5000)); // Sleep for 5 seconds
      console.log('After sleep');
    },
    async submit() {
      if (this.showForgot) {
        // sign in
        try {
          let userCredential = await signInWithEmailAndPassword(
            this.auth,
            this.email,
            this.password
          );

          // get username
          let uid = userCredential.user.uid;
          console.log("starting auth");

          let customerData = await getCustomerInfo(uid);
          const [username, Organization, Price, config] = customerData;

          // set auth state to true, usernam
          this.$store.commit("setLogin", {
            authStatus: true,
            username: username,
            uid: uid,
            organization: Organization,
            price: Price,
            config: config,
          });
          console.log("signed in successfully");
          // go to bookshelf
          router.push("/bookshelf");
        } catch (error) {
          const errorCode = error.code;
          const errorMessage = error.message;
          // this.errorMessage = errorMessage;
          this.errorMessage = "Invalid Email-Password Combination.";
          this.alert = true;
          console.log(errorMessage);
        }
      }

      else {
        sendPasswordResetEmail(this.auth, this.email)
          .then((userCredential) => {
            // Signed in
            this.errorMessage = `Password Reset Link sent to ${this.email}`;
            this.alert = true;
            this.dialog = false;
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            this.errorMessage = errorMessage;
            this.alert = true;
            console.log(errorMessage);
          });

        // let res = await this.sleep()
        this.resetState()
      }
    },
  },
};
</script>

<style scoped>
.home {
  font-size: 23px !important;
  font-weight: 300 !important;
  font-family: "Kumbh Sans", sans-serif !important;
}

.closebtn {
  color: black !important;
  position: relative;
  bottom: 200px !important;
  left: 5% !important;
  font-size: 20px;
}

.resetbtn {
  color: black !important;
  position: relative;
  bottom: 0px !important;
  left: -3% !important;
  font-size: 10px !important;
}

.v-application .primary {
  background-color: #38536d !important;
  border-color: #38536d !important;
}

.policy {
  /* white-space: nowrap !important; */
  /* overflow: hidden !important; */
  /* position: absolute !important; */
  font-size: 15px !important;
}

.conditions {
  font-size: 12px !important;
  font-style: italic !important;
}

.routerLink {
  /* text-decoration: underline !important; */
  text-decoration-color: white;
}
</style>
