import {
    auth,
    signOut
} from "../../../firebase/config.js";


import router from '@/router'


const getDefaultState = () => {
    return {
        firebaseAuth: auth,
        isAuthenticated: false,
        sessionID: "",
        username: "",
        organization: "",
        price: "",
        uid: "",
        config: {}
    }
}

export default {
    state: getDefaultState(),
    mutations: {
        setLogin(state, { authStatus, username, uid, organization, price, config }) {
            state.isAuthenticated = authStatus
            state.username = username
            state.uid = uid
            state.organization = organization
            state.price = price
            state.config = config
        },
        resetAuthState(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        logOut({ commit }) {
            signOut(auth).then(() => {
                commit("resetAuthState")
                commit("resetPDFState", null, { root: true })
                sessionStorage.clear()
                router.push('/');
                console.log("signed out successfully")

            })
                .catch(error => {
                    commit("resetAuthState")
                    commit("resetPDFState", null, { root: true })
                    sessionStorage.clear()
                    router.push('/');
                    console.log("signed out successfully")
                });
        },
    }
}