<template>
<div>
    <router-view />
</div>
</template>

<style scoped>
</style>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    computed: {}
}
</script>
