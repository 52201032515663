<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex text-center>
        <v-icon class="not-found-icon" color="red darken-2"
          >mdi-alert-circle-outline</v-icon
        >
        <h1 class="not-found-heading">404 Not Found</h1>
        <p class="not-found-message">
          Oops! The page you're looking for doesn't exist.
        </p>
        <v-btn color="primary" outlined @click="reloadPage">Take me home</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import router from "../../router";

export default {
  name: "NotFound",
  methods: {
    reloadPage() {
      router.push("/");
    },
  },
};
</script>

<style scoped>
.not-found-icon {
  font-size: 6rem;
  margin-bottom: 2rem;
}
.not-found-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.not-found-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
</style>
