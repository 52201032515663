<template>
  <v-app>
    <v-row justify="center">
      <v-dialog
        class="rounded-0"
        v-model="dialog"
        transition="dialog-top-transition"
        width="28vw"
        max-width="28vw"
        max-height="50vh"
        @keydown.esc="$store.commit('setAddWordModal')"
        scrollable
      >
        <v-card class="addWords rouded-0">
          <v-toolbar>
            <v-toolbar-title>
              <h3 class="addWords">Custom Word List</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              color="black"
              @click="$store.commit('setAddWordModal')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="mb-5"> </v-list-item-title>
                <v-list-item-title>
                  <v-combobox
                    class="combo"
                    ref="tagClear"
                    v-model="currentWords"
                    label="Type Word + Enter (Case Sensitive)"
                    multiple
                    deletable-chips
                    chips
                    clearable
                  >
                    <template #selection="{ item }">
                      <v-chip
                        class="black--text chip"
                        color="teal lighten-4"
                        close
                        @click:close="deleteWordinBatch(item)"
                        >{{ item }}</v-chip
                      >
                    </template>
                  </v-combobox>
                </v-list-item-title>
                <v-card-actions>
                  <v-btn
                    class="white--text"
                    color="grey darken-4"
                    @click="submit"
                  >
                    + Words
                  </v-btn>
                </v-card-actions>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>My Added Words</v-list-item-title>
                <v-container>
                  <v-chip-group
                    ref="chipClear"
                    v-model="selectedWords"
                    :key="chipKey"
                    :mandatory="mandatory"
                    column
                    multiple
                  >
                    <v-chip
                      filter
                      class="chip"
                      color="teal lighten-4"
                      v-for="(word, idx) in internalwordList"
                      :key="idx"
                    >
                      {{ word }}
                    </v-chip>
                  </v-chip-group>
                </v-container>
                <v-card-actions>
                  <v-btn color="red lighten-3" @click="clearSelected">
                    Clear Selected
                  </v-btn>
                  <v-btn color="red lighten-3" @click="clearAll">
                    Clear All
                  </v-btn>
                </v-card-actions>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import { documentId } from "@firebase/firestore";
export default {
  data() {
    return {
      // internalwordList being added
      currentWords: [],
      // added internalwordList that are selected
      selectedWords: [],
      // all internalwordList
      internalwordList: [],
      active: false,
      chipKeyit: 0,
      mandatory: false,
    };
  },

  methods: {
    // emits internal wordlist as an event called wordsUpadated
    updateSettingsWordList() {
      this.$emit("addWordsUpdated", this.internalwordList);
    },

    // deletes word in add combox before commited
    deleteWordinBatch(word) {
      this.currentWords = this.currentWords.filter((x) => x !== word);
    },

    submit() {
      let oldWords = this.internalwordList.map((x) => x.trim());
      let currentWords = this.currentWords;
      this.internalwordList = oldWords.concat(currentWords);

      if (currentWords.length > 0) {
        this.$refs.tagClear.clearableCallback();
      }

      // send up to settings state
      this.updateSettingsWordList();

      // close modal
      // this.$store.commit('setAddWordModal')
    },

    clearAll() {
      this.internalwordList = [];
      console.log("cleared wordlist");
      console.log(document.getElementsByName);

      // send up to settings state
      this.updateSettingsWordList();
    },

    clearSelected() {
      console.log(this.selectedWords);
      this.selectedWords.sort().forEach((idx) => {
        console.log(idx);
        this.internalwordList.splice(idx, 1);
      });
      console.log(this.$refs.chipClear);

      // this.$refs.clearableCallback()
      this.chipKeyit += 1;

      let chips = document.getElementsByClassName("mdi-check");
      console.log(chips);
      Array.prototype.forEach.call(chips, (el) => {
        el.click();
      });

      // send up to settings state
      this.updateSettingsWordList();
    },
  },

  computed: {
    dialog() {
      return this.$store.state.books.showWordsAdd;
    },
    chipKey() {
      return this.chipKeyit;
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.addWords {
  //   margin-top: 1.4rem;
  //   margin-bottom: 0.5rem;
  font-size: 32px !important;
  font-family: "Kumbh Sans", sans-serif !important;
  font-weight: 200 !important;
}

.chip {
  font-size: 16px !important;
  font-family: "Kumbh Sans", sans-serif !important;
  font-weight: 400 !important;
  // font-family: Arial, Helvetica, sans-serif !important;
}

.combo {
  font-family: Arial, Helvetica, sans-serif !important;
}
</style>
