<template>
  <v-app class="vs_inner pdfLoader">
    <div v-if="loadingStatus">
        <Loader />
    </div>

    <!-- <div class="loader" v-if="loadingStatus">
      <v-card-title class="ml-0 loader_title">
        {{ loadingMessage }}
      </v-card-title>
      <v-progress-linear value="100" height="50" indeterminate stream buffer-value="100"></v-progress-linear>
    </div> -->

    <ejs-pdfviewer id="pdfviewer" ref="pdfviewer" height="85vh" :serviceUrl="pdfServiceURL" :documentPath="pdfURL"
      :documentLoad="documentLoad" :importSuccess="successAdded" :annotationAdd="userAddedMarkup"
      :annotationRemove="userRemovedMarkup" :annotationSelect="annotationSelect" :commentSelect="commentSelect"
      :commentAdd="userAddedMarkup" :commentEdit="userAddedMarkup" :commentDelete="userRemovedMarkup"
      :downloadFileName="fileName" :commentStatusChanged="userAddedMarkup"
      :strikethroughSettings="{ thickness: '1000%', opacity:1000, width: '150%', color: 'red' }">
      <!-- :annotationAdd="userAddedAnnotation" -->
      <!-- http://localhost:6001/api/pdfviewer -->
      <!-- https://ej2services.syncfusion.com/production/web-services/api/pdfviewer -->
      <!-- PDF_Succinctly.pdf -->
    </ejs-pdfviewer>
  </v-app>
</template>

<script>
import Vue from "vue";
import {
  PdfViewerPlugin,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
} from "@syncfusion/ej2-vue-pdfviewer";

import { mapState, mapGetters } from "vuex";
import Loader from "./Loader.vue";

Vue.use(PdfViewerPlugin);

export default {
  name: "PDFViewer",
  components: {
        Loader,
    },
  data: function () {
    return {
      absolute: true,
      overlay: true,
      zIndex: 99999,
      showComments: true,
      testPath: "https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf"
    };
  },
  provide: {
    PdfViewer: [
      Toolbar,
      Magnification,
      Navigation,
      LinkAnnotation,
      BookmarkView,
      ThumbnailView,
      Print,
      TextSelection,
      TextSearch,
      Annotation,
      FormFields,
      FormDesigner,
    ],
  },
  methods: {
    async documentLoad() {
      let viewer = this.$refs.pdfviewer.ej2Instances;
      viewer.toolbar.showToolbar(true);
      viewer.toolbar.showOpenOption(false);
      viewer.toolbar.showAnnotationToolbar(true);
      viewer.toolbar.showFormDesignerEditTool(true);
      viewer.toolbar.showSubmitForm(true);
      viewer.toolbar.showCommentOption(true);
      viewer.toolbar.annotationToolbarModule.showCommentPanelTool(true);
      viewer.toolbar.annotationToolbarModule.enableCommentPanelTool(true);
      viewer.toolbar.annotationToolbarModule.commentItem.setCustomValidity(
        true
      );
      viewer.annotationSettings.author = "Proofcheck";
      viewer.scrollSettings.delayPageRequestTimeOnScroll = 0;

      let preAnnotations = []
      preAnnotations = [...viewer.annotationCollection]
      if (preAnnotations.length > 0) {
          this.$store.dispatch("LoadUserAnnotations", preAnnotations)
      }
      document.getElementById("pdfviewer_annotation_commentPanel").click();
      // document.getElementById("pdfviewer_thumbnail-view").click()
      // this.goFullScreen();
    },

    goFullScreen() {
      var element = document.querySelector("#workflow");
      element
        .requestFullscreen({ navigationUI: "show" })
        .then(function () {
          // element has entered fullscreen mode successfully
        })
        .catch(function (error) {
          // element could not enter fullscreen mode
        });
    },
    styleComments() {
      // const elements = document.querySelectorAll('.e-editable-value');
      // const patternString = "\\[(.*?)\\]";
      // const pattern = new RegExp(patternString);
      // console.log("Styling Comments");
      // elements.forEach(element => {
      //     const matches = element.innerHTML.match(/\{.*?\}/g);
      //     element.innerHTML = element.innerHTML.replace(/\{.*?\}/g, '<br>$&');
      //     element.innerHTML = element.innerHTML.replace(/\[.*?\]/g, '$&<br><br>');
      //     element.innerHTML = element.innerHTML.replace(/asterisk:/g, '$&<br><br>');
      // });
    },
    async sleep() {
      console.log('Before sleep');
      await new Promise(resolve => setTimeout(resolve, 5000)); // Sleep for 5 seconds
      console.log('After sleep');
    },
    // feature apis
   successAdded() {
      console.log("annotations added successfully");
    },
    // user added comment to an annotation, need to update both local state?
    userAddedMarkup(annot) {
      const viewer = this.$refs.pdfviewer.ej2Instances;
      const addedViewerAnnot = viewer.annotationCollection.find(
        (x) => x.annotationId === annot.annotationId
      );
      this.$store.dispatch("addMarkup", { annot, addedViewerAnnot });
      console.log("Added or Edited Comment/HandDrawn Annotation");
    },
    // user individual removal of annotation
    userRemovedMarkup(annot) {
      this.$store.dispatch("removeMarkup", annot);
      console.log("User Removed annotation/comment");
    },
    async commentSelect(annot) {
      console.log("User selected annotation")
    },
    annotationSelect(annot) {
      let xrayOn = this.$store.state.pdf.featureState.x_ray.on;
      if (xrayOn) {
        let textGroup = annot.annotation.subType;
        let id = annot.annotationId;
        this.$store.dispatch("focusTextGroup", { textGroup, id });
        const viewer = this.$refs.pdfviewer.ej2Instances;
        viewer.annotation.clearSelection;
      }
    },
  },

  computed: {
    ...mapGetters({
      pdfURL: "pdfURL",
      selectedBook: "selectedBook",
      pdfServiceURL: "pdfServiceURL",
      featureState: "featureState",
      totalOnAnnotations: "totalOnAnnotations",
      widows_orphans: "widows_orphans",
      runts: "runts",
      stacks: "stacks",
      ladders: "ladders",
      word_division: "word_division",
      links: "links",
      typos: "typos",
      x_ray: "x_ray",
      user: "user",
      fileName: "pdfFileName",
      loadingStatus: "loadingStatus",
      loadingMessage: "loadingMessage",
    }),

    ...mapState({
      username: (state) => state.auth.username,
    }),
  },
  updated(){
    console.log("something changed");
    this.styleComments();
  },
  watch: {
    // add changed annotations to PDF component, mirrors state changes at agg level
    // like when we toggle of a feature, need pdf side to update
    totalOnAnnotations(newAnnotations) {
      const viewer = this.$refs.pdfviewer.ej2Instances;
      viewer.deleteAnnotations();
      viewer.addAnnotation(newAnnotations);
  },
    stacks(newValues, oldValues) {
      let feature = "stacks";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
    ladders(newValues, oldValues) {
      let feature = "ladders";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
    widows_orphans(newValues, oldValues) {
      let feature = "widows_orphans";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
    runts(newValues, oldValues) {
      let feature = "runts";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
    word_division(newValues, oldValues) {
      let feature = "word_division";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
    x_ray(newValues, oldValues) {
      let feature = "x_ray";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
    typos(newValues, oldValues) {
      let feature = "typos";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
    user(newValues, oldValues) {
      let feature = "user";
      this.$store.dispatch("saveAnnotationsStorage", feature);
    },
  },

  beforeDestroy() {
    const viewer = this.$refs.pdfviewer.ej2Instances;
    viewer.deleteAnnotations();
  },
};
</script>

<style lang="scss" scoped>
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-buttons/styles/material.css";
@import "~@syncfusion/ej2-dropdowns/styles/material.css";
@import "~@syncfusion/ej2-inputs/styles/material.css";
@import "~@syncfusion/ej2-navigations/styles/material.css";
@import "~@syncfusion/ej2-popups/styles/material.css";
@import "~@syncfusion/ej2-splitbuttons/styles/material.css";
@import "~@syncfusion/ej2-vue-pdfviewer/styles/material.css";

#pdfviewer {
  height: 100vh;
}

.hidden {
  opacity: 20% !important;
}

$vert: 35vh;
$horz: 22vw;

.loader {
  position: absolute !important;
  // left: $horz;
  // top: $vert;

  left: 28% !important;
  right: 45% !important;
  top: 30% !important;

  width: 30%;
  z-index: 9999 !important;
  background-color: rgb(233, 232, 214) !important;
  // opacity: 100% !important;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 10px;
  font-weight: 400;
}

/* Card  title */
.v-card__title {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 25px;
  font-weight: 400;
  margin-left: 0vw !important;
}

/* Progress linear */
.v-progress-linear {
  background-color: rgb(102, 102, 180) !important;
}

// div#pdfviewer.e-control.e-pdfviewer.e-lib {
//   width: 100% !important;

//   div#pdfviewer_mainContainer.e-pv-main-container {
//     background: red !important;
//     width: 50% !important;
//     div#pdfviewer_commantPanel.e-pv-comment-pannel {
//       color: red !important;

//       div#pdfviewer_commentscontentcontainer.e-pv-comments-content-container {
//       }
//     }
//   }
// }

#pdfviewer_commenttextbox_wrap>span.e-editable-value {
  color: rgb(190, 24, 24) !important;
}

span.e-editable-value {
  color: red !important;
}

div.e-pv-comments-container {
    border: 2px;
    border-color: black !important;
    border-radius: 4px;
    border-style: groove;
}
</style>

