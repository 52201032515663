import axios from 'axios';
import router from '../../router'

const PDF_URL = process.env.VUE_APP_PDF_URL
const PYTHON_URL = process.env.VUE_APP_PYTHON_URL


const featureMap = {
  "Runt": "runts",
  "Ladder": "ladders",
  "Widow_Orphan": "widows_orphans",
  "Stack": "stacks",
  "Word_Division": "word_division",
  "Link": "links",
  "Typo": "typos",
}

const getDefaultState = () => {
  return {
    pdfBucketPath: null,
    docBucketPath: null,
    pdfServiceURL: PDF_URL,
    selectedBook: {},
    initializedPDF: false,
    featureState: {
      widows_orphans: {
        title: "Widows & Orphans",
        feature: "widows_orphans",
        icon: "mdi-format-paragraph-spacing",
        on: false,
        disabled: false,
        annotations: []
      },
      runts: {
        title: "Runts",
        feature: "runts",
        icon: "mdi-format-align-top",
        on: false,
        disabled: false,
        annotations: []
      },
      ladders: {
        title: "Ladders",
        feature: "ladders",
        icon: "mdi-ladder",
        on: false,
        disabled: false,
        annotations: []
      },
      stacks: {
        title: "Stacks",
        feature: "stacks",
        icon: "mdi-blinds-horizontal",
        on: false,
        disabled: false,
        annotations: []
      },
      word_division: {
        title: "Word Division",
        feature: "word_division",
        icon: "mdi-ab-testing",
        on: false,
        disabled: false,
        annotations: []
      },
      links: {
        title: "Hyperlinks",
        feature: "links",
        icon: "mdi-link",
        on: false,
        disabled: false,
        annotations: []
      },
      typos: {
        title: "Typos",
        feature: "typos",
        icon: "mdi-alphabetical-off",
        on: false,
        disabled: false,
        annotations: []
      },
      x_ray: {
        title: "X-Ray",
        feature: "x_ray",
        icon: "mdi-radiology-box-outline",
        on: false,
        disabled: false,
        annotations: [],
        full_annotations: [],
        group_selected: false,
        prevTextGroup: null,
        prevId: null
      },
      user: {
        title: "User",
        feature: "user",
        icon: "mdi-forum",
        on: false,
        disabled: false,
        annotations: []
      }
    },
    loadingStatus: false,
    loadingMessage: "Loading"
  }
}

export default {
  state: getDefaultState(),
  mutations: {
    setSelectedPDF(state, book) {
      state.selectedBook = { ...book }
    },
    setDocBucketPath(state, path) {
      state.docBucketPath = path
    },
    setPDFBucketPath(state, path) {
      state.pdfBucketPath = path
    },
    setAnnotations(state, { feature, annotations }) {
      const oldState = state.featureState[feature]
      const newState = { on: true, annotations: annotations }
      state.featureState[feature] = { ...oldState, ...newState }
      state.initializedPDF = true
    },
    setGroupSelect(state, { feature, annotations, full_annotations, group_selected, newId, newTextGroup }) {
      const oldState = state.featureState[feature]
      const newState = {
        on: true, annotations: annotations, full_annotations: full_annotations,
        group_selected: group_selected,
        prevId: newId,
        prevTextGroup: newTextGroup
      }
      state.featureState[feature] = { ...oldState, ...newState }
    },
    setSelectId(state, { newId, newTextGroup }) {
      const oldState = state.featureState.x_ray;
      const newState = { prevId: newId, prevTextGroup: newTextGroup }
      state.featureState.x_ray = { ...oldState, ...newState }
    },
    toggleLoading(state) {
      const features = ["typos", "word_division", "links", "widows_orphans", "runts", "stacks", "ladders", "x_ray"]
      for (const feature of features) {
        state.featureState[feature].disabled = !state.featureState[feature].disabled
      }
      state.loadingStatus = !state.loadingStatus
    },
    setLoadingMessage(state, message) {
      state.loadingMessage = message
    },
    resetPDFState(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    LoadUserAnnotations({ commit }, annotations) {
      const feature = "user"
      commit("setAnnotations", { feature, annotations })  
    },
    async featureWork({ commit, rootState, state, getters }, feature) {
      const currentFeatureState = getters.featureState(feature)
      const { on, annotations } = currentFeatureState


      const fullNames = {
        widows_orphans: "Widows & Orphans",
        stacks: "Stacks",
        ladders: "Ladders",
        runts: "Runts",
        typos: "Typos",
        word_division: "Bad Breaks"
      }
      var featureName = fullNames[feature]

      // if feature is on and has no annotations call API
      if (on && annotations.length == 0) {
        console.log("Call feature Work API: feature on and no annotation length")
        commit("toggleLoading")
        commit("setLoadingMessage", "Looking for errors")
 
        try {
          const config = rootState.auth.config;
          const payload = {
            feature: feature,
            book: state.selectedBook,
            config: config
          }
          // post feature API
          const featureRes = await axios.post(`${PYTHON_URL}/work`, payload)
          const strAnnotations = JSON.stringify(featureRes.data)
          const annotations = JSON.parse(strAnnotations)

          if (strAnnotations == '[{}]') {
            commit("setLoadingMessage", `No ${featureName}`)
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
          else {
            commit("setAnnotations", { feature, annotations }) // feature toggle happening in here
            let featureFull =  (feature != "user") ? featureName: `${feature} `
            commit("setLoadingMessage", `Found - ${annotations.length} ${featureFull}`)
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
          commit("toggleLoading")

        } catch (e) {
          console.log(e)
          router.push({
            name: "ErrorPage",
            params: {
              errorMessage: `${e.message} - featureWork, (pdf.js)`,
              stackTrace: e.stack
            }
          })
        }
      }
      // elif  feature on and pre-existing annotations (i.e. settings back navigation)
      else if (on && annotations.length > 0) {
        commit("toggleLoading")
        commit("setLoadingMessage", "Looking for errors")
        commit("setAnnotations", { feature, annotations }) // feature toggle happening in here
        // update loader to show results found
        console.log(feature);
        let featureFull = (feature != "user") ? featureName : `${feature}`
        console.log(featureFull);
        commit("setLoadingMessage", `Found - ${annotations.length > 0 ? annotations.length : "no annotations"} ${featureFull}`)
        await new Promise(resolve => setTimeout(resolve, 2000));
        commit("toggleLoading");
      }

      // feature is off
      // else { commit("toggleFeature", feature) }
      else {
        console.log("feature is off no API Call")

      }
    },

    addMarkup({ commit, state }, payload) {
      const { annot, addedViewerAnnot } = payload;
      const { id, text, annotation } = annot;

      let feature;
      if (annotation !== undefined) {
        if (featureMap.hasOwnProperty(annotation.subject)) {
          feature = featureMap[annotation.subject]
        }
        else {
          feature = "user"
        }
      } else {
        feature = "user"
      }

      const oldAnnotations = state.featureState[feature].annotations
      let annotations,
        annotationsWithoutTarget, targetAnnot,
        parentId, parentIds, parentAnnot;

      // hand drawn annotations
      if (annot.name == "annotationAdd") {
        annotations = [...oldAnnotations, addedViewerAnnot];
      }
      // comment reply | comment edited
      else if ((annot.name == "commentAdd") || (annot.name == "commentEdit")) {
        parentIds = oldAnnotations.map(x => x.annotationId)
        parentId = annotation.annotName
        parentAnnot = oldAnnotations.find(x => x.annotationId === parentId)

        // case where editing parent (first comment)
        if (parentIds.includes(id)) {
          // mutate note directly 
          parentAnnot.note = text
        }
        // non first edit or comment add 
        else {
          // mutate parent comments
          parentAnnot.comments = annotation.comments
        }
        annotationsWithoutTarget = oldAnnotations.filter(x => x.annotationId !== parentId)
        annotations = [...annotationsWithoutTarget, parentAnnot]
      }

      else if (annot.name == "commentStatusChanged") {
        parentIds = oldAnnotations.map(x => x.annotationId)
        parentId = annotation.annotName
        parentAnnot = oldAnnotations.find(x => x.annotationId === parentId)
        parentAnnot.review = annotation.review
        annotationsWithoutTarget = oldAnnotations.filter(x => x.annotationId !== parentId)
        annotations = [...annotationsWithoutTarget, parentAnnot]
      }
      commit("setAnnotations", { feature, annotations })
    },

    // when a pdf annotation is removed we want to mirror this action on the state
    removeMarkup({ commit, state }, annotation) {
      let feature, feature_entry, oldAnnotations,
        annotations, annotationIds, comment, parentAnnot,
        annotationsWithoutParent
      // remove general annotation
      if (annotation.name == "annotationRemove") {
        for ([feature, feature_entry] of Object.entries(state.featureState)) {
          oldAnnotations = feature_entry.annotations
          annotationIds = oldAnnotations.map(x => x.annotationId)
          if (annotationIds.includes(annotation.annotationId)) {
            annotations = [...oldAnnotations.filter(x => x.annotationId !== annotation.annotationId)]
            break;
          }
        }
      }
      // remove reply annotation
      else if (annotation.name == "commentDelete") {
        comment = annotation.annotation
        
        // feature = Object.keys(state.featureState).includes(comment.subject) ? comment.subject : "user"
        if (comment !== undefined) {
          if (featureMap.hasOwnProperty(comment.subject)) {
            feature = featureMap[comment.subject]
          }
          else {
            feature = "user"
          }
        } else {
          feature = "user"
        }
        oldAnnotations = state.featureState[feature].annotations
        parentAnnot = oldAnnotations.find(x => x.annotationId === comment.annotName)
        // mutate comments so without removed one
        parentAnnot.comments = parentAnnot.comments.filter(x => x.annotName !== annotation.id)
        annotationsWithoutParent = oldAnnotations.filter(x => x.annotationId !== comment.annotName)
        annotations = [...annotationsWithoutParent, parentAnnot]
      }
      commit("setAnnotations", { feature, annotations })
    },

    async focusTextGroup({ commit, state }, payload) {
      const { textGroup, id } = payload;
      let feature = "x_ray"
      let full_annotations = []
      let annotations = []
      let group_selected
      let currentState = state.featureState[feature]
      let prevId = currentState.prevId
      let newId = id
      let newTextGroup = textGroup
      let oldTextGroup = currentState.prevTextGroup

      console.log(id);

      if ((prevId == null) || (newId == prevId) || (newTextGroup !== oldTextGroup)) {
        commit("toggleLoading")
        commit("setLoadingMessage", "X-Ray Inspector Filtering")

        // filter case
        if (!currentState.group_selected) {
          full_annotations = currentState.annotations
          annotations = full_annotations.filter(x => x.subject == textGroup)

        }
        // unfilter
        else {
          annotations = currentState.full_annotations
          full_annotations = annotations
        }
        group_selected = !currentState.group_selected
        // let oldAnnotations = state.featureState[feature].annotations
        // annotations = oldAnnotations.filter(x => x.subject == textGroup)

        commit("setGroupSelect", { feature, annotations, full_annotations, group_selected, newId, newTextGroup })
        await new Promise(resolve => setTimeout(resolve, 1500));
        commit("toggleLoading")

      } else {
        commit("setSelectId", { newId, newTextGroup })
      }


    },

    // saves all feature state to highlight json
    async saveAnnotationsStorage({ state }, feature) {
      const payload = {
        feature: feature,
        book: { ...state.selectedBook },
        highlights: [...state.featureState[feature].annotations]
      }
      try {
        const savedRes = await axios.post(`${PYTHON_URL}/save`, payload)
        console.log("Saved Annotations")
      } catch (e) {
        router.push({
          name: "ErrorPage",
          params: {
            errorMessage: `${e.message} - saveAnnotationsStorage, (pdf.js)`,
            stackTrace: e.stack
          }
        })
      }
    },
  },

  getters: {
    // all annotations flattened for pdf viewer
    totalOnAnnotations(state) {
      let totalAnnotations = []
      for (const [key, feature_entry] of Object.entries(state.featureState)) {
        if (feature_entry.on) {
          // console.log(entry.on)
          totalAnnotations = totalAnnotations.concat(feature_entry.annotations)
        }
      }
      return totalAnnotations
    },
    featureState: (state) => (feature) => {
      return state.featureState[feature]
    },
    widows_orphans(state) {
      return state.featureState["widows_orphans"].annotations
    },
    runts(state) {
      return state.featureState["runts"].annotations
    },
    stacks(state) {
      return state.featureState["stacks"].annotations
    },
    ladders(state) {
      return state.featureState["ladders"].annotations
    },
    word_division(state) {
      return state.featureState["word_division"].annotations
    },
    links(state) {
      return state.featureState["links"].annotations
    },
    typos(state) {
      return state.featureState["typos"].annotations
    },
    x_ray(state) {
      return state.featureState["x_ray"].annotations
    },
    user(state) {
      return state.featureState["user"].annotations
    },
    pdfURL(state) {
      return state.pdfBucketPath
    },
    docURL(state) {
      return state.docBucketPath
    },
    selectedBook(state) {
      return state.selectedBook
    },
    toggleItems(state) {
      let items = state.featureState
      return Object.values(items)
        // .filter(x => x.feature !== "user")
    },
    pdfServiceURL(state) {
      return state.pdfServiceURL
    },
    pdfFileName(state) {
      return `${state.selectedBook.title}_${state.selectedBook.tag}.pdf`
    },
    loadingStatus(state) {
      return state.loadingStatus
    },
    loadingMessage(state) {
      return state.loadingMessage
    },
  }
}


// Add: User/Comment * , Standard/Comment X, Reply/Comment X

// Delete: User/Comment, Standard/Comment X, Reply/Comment X

// Edit: User/Comment, Standard/Comment X, Reply/Comment X