import Vue from 'vue'
import store from '../store/index.js'
import router from '../router'

const PYTHON_URL = process.env.VUE_APP_PYTHON_URL


const contentTypes = {
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    pdf: "application/pdf"
}


async function getCustomerBooks(organization) {
    try {
        let basicInfo = await axios.get(
            `${PYTHON_URL}/tree/${organization}`
        );
        return basicInfo.data
    } catch (e) {
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${e.message} - getCustomerBooks (linodeService.js)`,
                stackTrace: e.stack
            }
        })
    }
}

async function UploadFile(fileName, assetsPath, file) {
    const formData = new FormData()
    formData.append("action", "upload")
    formData.append("path", assetsPath)
    formData.append("name", fileName)
    formData.append("file", file)

    try {
        let uploadResult = await axios({
            method: "post",
            url: `${PYTHON_URL}/upload`,
            data: formData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        let downloadURL = uploadResult.data
        console.log(uploadResult)
        return downloadURL
    } catch (e) {
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${e.message} - UploadFile (linodeService.js)`,
                stackTrace: e.stack
            }
        })
    }
}

// uploads book to Linode Volume, returns a download path
async function UploadBookFiles(files, bookSettingsData) {
    const { title, tag } = bookSettingsData
    const organization = store.state.auth.organization
    const assetsPath = `${organization}/${title}/${tag}/`.replaceAll(" ", "_")
    // upload one file at a time [settings, pdf, stylesheet]

    try {
        // upload document files
        let filteredFiles = [...files.filter(file => file !== null)]
        for (const file of filteredFiles) {
            // now we upload to Linode folders for the given org/my_book/v1/file.pdf
            let downloadURL = await UploadFile(file.name, assetsPath, file)
            // set download urls in local path 
            switch (file.type) {
                case contentTypes.pdf:
                    store.commit("setPDFBucketPath", downloadURL)
                    bookSettingsData.proofURL = downloadURL
                    bookSettingsData.proofFileName = file.name
                    bookSettingsData.assetsPath = assetsPath
                    break;
                case contentTypes.docx:
                    store.commit("setDocBucketPath", downloadURL)
                    bookSettingsData.docURL = downloadURL
                    break;
                case Default:
                // do nothing
            }
        }

        // upload settings and metadata files
        const BookSettingsBlob = new Blob([JSON.stringify(bookSettingsData)], { type: 'application/json' });
        let rest = await UploadFile("book_settings.json", assetsPath, BookSettingsBlob)

        return { assetsPath: assetsPath, proofURL: bookSettingsData.proofURL }
    } catch (e) {
        console.log(e)
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${e.message} - UploadBookFiles, (linodeService.js)`,
                stackTrace: e.stack
            }
        })
    }
}

async function preProcessBook(bookInfo) {
    try {
        const res = await axios.post(`${PYTHON_URL}/pre_process`, bookInfo)
        return res
    } catch (e) {
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${e.message} - preProcessBook,(linodeService.js)`,
                stackTrace: e.stack
            }
        })
    }
}

async function deleteBookVersion(payload) {
    try {
        let deleteResult = await axios.post(`${PYTHON_URL}/delete`, payload)
        return deleteResult
    } catch (e) {
        console.log(e)
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${e.message} - deleteBookVersion, (linodeService.js)`,
                stackTrace: e.stack
            }
        })
    }
}

async function editBookVersion(bookInfo) {
    const { book, wordDoc } = bookInfo
    let bookSettings = book
    const { assetsPath } = bookSettings

    try {
        // if we added a word doc upload it
        if (wordDoc !== null) {
            let downloadURL = await UploadFile(wordDoc.name, assetsPath, wordDoc)
            store.commit("setDocBucketPath", downloadURL)
            bookSettings.docURL = downloadURL
        }
        // deletes highlights where things changed and saves down the new settings json
        let formData = new FormData();
        formData.append('data', JSON.stringify(bookSettings));

        let payload = {
            method: "post",
            url: `${PYTHON_URL}/edit`,
            data: formData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        }
        let res = await axios(payload)

    } catch (e) {
        router.push({
            name: "ErrorPage",
            params: {
                errorMessage: `${e.message} - editBookVersion, (linodeService.js)`,
                stackTrace: e.stack
            }
        })
    }
}

export { UploadBookFiles, preProcessBook, getCustomerBooks, deleteBookVersion, editBookVersion }

