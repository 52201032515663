import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import router from '@/router'
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth"
import pdf from "./modules/pdf"
import books from "./modules/book"

Vue.use(Vuex)


export default new Vuex.Store({
    // plugins: [createPersistedState({storage: window.sessionStorage})],

    modules: {auth, pdf, books},
    
    state: {}, 
    
    mutations: {},
    
    actions: {},
    
    getters: {},

})



