<template>
  <v-app class="bs_outer">
    <v-app-bar class="appBar" elevation="10" min-height="80px">
      <v-row align="center" justify="center" class="mt-0 flex-shrink-0">
        <v-col cols="2">
          <router-link class="routerLink" :to="'/home'">
            <v-icon color="">mdi-home</v-icon>
          </router-link>
        </v-col>
        <v-col class="ml-n10">
          <v-img
            width="200px"
            contain
            class="ml-n2 mt-4"
            src="@/assets/beta_logo.png"
          />
        </v-col>
      </v-row>
      <v-col />
      <v-row align="center" justify="center" class="mt-1 flex-shrink-0">
        <v-col />
        <v-icon>mdi-account</v-icon>
        <v-toolbar-title class="account ml-2 mr-2">
          {{ username }}
        </v-toolbar-title>
        <v-btn
          color="rgba(94,140,148)"
          class="white--text logoutBtn mr-5"
          @click="logOut"
        >
          Sign Out
        </v-btn>
      </v-row>
    </v-app-bar>

    <BookTree />
  </v-app>
</template>

<script>
import BookTree from "../../components/BookTree.vue";
import Loader from "../../components/Loader.vue";

export default {
  components: {
    BookTree,
    Loader,
  },
  data() {
    return {};
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOut");
    },
  },
  mounted() {},
  computed: {
    username() {
      console.log(this.$store.state.auth.username);
      return this.$store.state.auth.username;
    },
  },
};
</script>

<style lang="scss" scoped>
.appBar {
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 400;

  .logoutBtn {
    font-weight: 400 !important;
  }
}

.account {
  margin: 16px;
  font-size: 18px !important;
}

.content {
  margin-left: 22px;
}

.upgrade {
  margin: 10px;
  border: 1px purple solid;
}

.v-application .primary {
  background-color: #38536d !important;
  border-color: #38536d !important;
}

.routerLink {
  /* text-decoration: underline !important; */
  text-decoration-color: grey !important;
}
</style>
