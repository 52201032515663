<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex text-center>
        <v-icon class="error-icon" color="red darken-2"
          >mdi-alert-circle-outline</v-icon
        >
        <h1 class="error-heading">Oops! Something went wrong.</h1>
        <v-btn class="mt-10 mb-10" color="primary" outlined @click="reloadPage"
          >Take me back</v-btn
        >
        <p class="mt-10 error-message">{{ errorMessage }}</p>
        <pre class="stack-trace">
          <code>{{ stackTrace }}</code>
        </pre>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import router from "../../router";

export default {
  name: "ErrorPage",
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
    stackTrace: {
      type: String,
      required: true,
    },
  },
  methods: {
    reloadPage() {
      router.push("/");
    },
  },
};
</script>

<style scoped>
.error-icon {
  font-size: 6rem;
  margin-bottom: 2rem;
}
.error-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.error-message {
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.stack-trace {
  font-size: 1.2rem;
  margin-top: 1rem;
  background-color: black;
  color: white;
  padding: 1rem;
  font-family: monospace;
  white-space: pre-wrap;
}
</style>
